import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import MasonryResponsive from "../components/masonryResponsive"
import Select from 'react-select'
import ArticleTeaser from "../components/articleTeaser"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { getCrumbsObj } from "../utils/ug-utils"
import BannerAd, { BANNERAD_CHILDREN_NEWS } from "../components/bannerAd"
import "../styles/news.scss"
import HeroSpotlight from "../components/heroSpotlight"
import { useLocation, navigate } from "@reach/router"
import GridParent from "../components/gridParent"
import Grid from "../components/grid"
import GridCell from "../components/gridCell"
import Breadcrumbs from "../components/breadcrumbs"

const NewsPage = ({ data, pageContext }) => {
  
  const crumbsObj = getCrumbsObj(pageContext)
  const articlesData = data.articles.edges
  const newsCategories = data.newsCategories.edges.map((edge) => { return {value: edge.node.id, label: edge.node.name} })
  const newsCauses = data.newsCauses.edges.map((edge) => { return {value: edge.node.id, label: edge.node.name} })
  const units = data.units.edges.map((edge) => { return {value: edge.node.id, label: edge.node.name} })
  const heroImage = <StaticImage src="../images/news-bg.jpg" alt="U of G News" />
  const breakpointColumnsObj = {
    default: 3,
    768: 2,
    576: 1
  };
  const customSelectStyles = {
    dropdownIndicator: base => ({
      ...base,
      color: "#c20430"
    })
  };
  const customSelectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary75: "#c7395a",
      primary50: "#ca788b",
      primary25: "#fde6ec",
      primary: "#c20430"
    }
  })

  const [filters, setFilter] = useState({
    field_news_category: "",
    field_cause: "",
    field_college: "",
  })
  const [list, setList] = useState(articlesData.filter((article) => 
    filters.field_news_category !== "" ? article.node.relationships.field_news_category.some((category) => (category.id === filters.field_news_category)) : true &&
    filters.field_cause !== "" ? article.node.relationships.field_cause.some((cause) => (cause.id === filters.field_cause)) : true &&
    filters.field_college !== "" ? article.node.relationships.field_college.some((college) => (college.id === filters.field_college)) : true
  ))

  const location = useLocation();
  useEffect(() => {
      const mappings = {
          "Alumni": { field_news_category: '07a1cd8d-2a3d-533f-8c8b-3abc1fe4d9bb' },
          "Research": { field_news_category: '9bd152d7-54c5-560f-8ddd-dc9448110174' },
          "Environment": { field_cause: 'ae0352f0-e026-5baf-8b63-daf564105b0a' },
          "Human_Health": { field_cause: 'aa97e105-336e-56d8-9439-47b0a8e4fe9f' },
          "Student_&_Campus_Experience": { field_cause: 'ca3e4959-4d7f-5d5f-bcf3-dcbeabf367f7' },
          "College_of_Arts": { field_college: '3d18a6e2-c0ff-5813-987c-a35e6c0521f7' },
          "College_of_Biological_Science": { field_college: '12cb5904-406e-5015-8a02-da91b2bed225' }
      };

      const updateFilters = () => {
          const searchKeyword = Object.keys(mappings).find(keyword => location.search.includes(keyword));
          if (searchKeyword) {
              Object.assign(filters, mappings[searchKeyword]);
              setFilter(prevFilters => ({
                ...prevFilters,
                ...mappings[searchKeyword]
              }));
              const newPath = location.pathname;
              const newState = { ...window.history.state };
              navigate(newPath, { state: newState });
          }
      };
      updateFilters(); // Initial update

      return () => {
          // Clean up any resources if needed
      };
  }, [location.search]);


  useEffect(() => {
    // console.log("filter", filters)
    setList(articlesData.filter((article) => 
      filters.field_news_category !== "" ? article.node.relationships.field_news_category.some((category) => (category.id === filters.field_news_category)) : true &&
      filters.field_cause !== "" ? article.node.relationships.field_cause.some((cause) => (cause.id === filters.field_cause)) : true &&
      filters.field_college !== "" ? article.node.relationships.field_college.some((college) => (college.id === filters.field_college)) : true
    ))
  }, [filters, articlesData])

  const handleOnChangeCategory = (e) => {
    let value = e === null ? "" : e.value;
    setFilter(previousState => {
      return { ...previousState, field_news_category: value }
    });
  }

  const handleOnChangeCause = (e) => {
    let value = e === null ? "" : e.value;
    setFilter(previousState => {
      return { ...previousState, field_cause: value }
    });
  }

  const handleOnChangeCollege = (e) => {
    let value = e === null ? "" : e.value;
    setFilter(previousState => {
      return { ...previousState, field_college: value }
    });
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "article-list-page",
        }}
      />
      <Seo title={`Stories`} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="short-hero image">
        <Hero staticImage={heroImage} />
        <div>
          <HeroSpotlight>
            <h1 className="fancy-title">Stories</h1>
          </HeroSpotlight>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Breadcrumbs targetUrl="/stories" activeTitle="Stories" />
          {/* <Row className="row-with-vspace site-content">
            <Col sm={4}>
              <Select 
                options={newsCategories} 
                onChange={handleOnChangeCategory} 
                placeholder="All Categories" 
                isClearable={true}
                styles={customSelectStyles}
                theme={customSelectTheme}
                value={newsCategories?.find(option => option.value === filters.field_news_category) ?? null}
              />
            </Col>
            <Col sm={4}>
              <Select 
                options={newsCauses} 
                onChange={handleOnChangeCause} 
                placeholder="All Causes" 
                isClearable={true} 
                styles={customSelectStyles}
                theme={customSelectTheme}
                value={newsCauses?.find(option => option.value === filters.field_cause) ?? null}
              />
            </Col>
            <Col sm={4}>
              <Select 
                options={units} 
                onChange={handleOnChangeCollege} 
                placeholder="All Colleges" 
                isClearable={true} 
                styles={customSelectStyles}
                theme={customSelectTheme}
                value={units?.find(option => option.value === filters.field_college) ?? null}
              />
            </Col>
          </Row> */}
          <Row className="row-with-vspace site-content card-collection-wrapper">
            <Col md={12} className="content-area">
              {/**** Body content ****/}
              {list.length > 0 ? (
                  <Grid>
                    {list.map(({ node }, index) => (
                      <GridCell key={index}>
                        <ArticleTeaser node={node} className="grid-card" />
                      </GridCell>
                    ))}
                  </Grid>
              ) : (
                <p>No articles to display.</p>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  {
    articles: allNodeArticle(limit: 1000, sort: { fields: created, order: DESC }) {
      edges {
        node {
          ...Article
          _masonry_card_image: relationships {
            field_hero_image {
              ...CardImageMasonry
            }
          }
        }
      }
    }
    newsCategories: allTaxonomyTermNewsCategory(
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    newsCauses: allTaxonomyTermNewsCause(
      # Ensure there are nodes
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    units: allTaxonomyTermUnits(
      # Ensure there are nodes
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    articleTags: allTaxonomyTermTags(
      # Ensure there are nodes
      sort: { fields: name }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
  }
`
